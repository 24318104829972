import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "images/logo/logo.png";

const Login: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission
    navigate("/dashboard"); // Redirect to /dashboard
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Main Content */}
      <div className="flex flex-col lg:flex-row flex-grow">
        {/* Left Section with Logo */}
        <div className="flex flex-col justify-center items-center bg-white lg:w-1/2 p-8 lg:p-16">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-36 h-36 lg:w-48 lg:h-48">
              {/* Responsive Logo */}
              <img
                src={logo}
                alt="Logo Factoconn"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>

        {/* Right Section with Form */}
        <div className="flex flex-col justify-center items-center w-full lg:w-1/2 p-8 lg:p-16">
          <div className="bg-white shadow-md rounded-lg p-6 lg:p-8 w-full max-w-md">
            <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 text-center">
              Connectez-vous à votre compte
            </h2>
            <p className="text-sm text-gray-500 text-center mt-2">
              Bienvenue ! Veuillez entrer vos informations.
            </p>

            {/* Form */}
            <form className="space-y-6 mt-6" onSubmit={handleSubmit}>
              {/* Email Field */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Entrez votre email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                />
              </div>

              {/* Password Field */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mot de passe
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder="••••••••"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                />
              </div>

              {/* Options: Remember Me & Forgot Password */}
              <div className="flex flex-col lg:flex-row items-center justify-between gap-2 lg:gap-0">
                <label className="flex items-center space-x-2 text-sm">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <span>Se souvenir pendant 30 jours</span>
                </label>
                <a
                  href="#"
                  className="text-sm text-blue-500 hover:underline"
                >
                  Mot de passe oublié
                </a>
              </div>

              {/* Se connecter Button */}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-md text-sm font-semibold hover:bg-blue-600 transition"
              >
                Se connecter
              </button>
            </form>

            {/* Join Factoconn Link */}
            <p className="text-sm text-center text-gray-500 mt-6">
              Vous n'avez pas de compte ?{" "}
              <a
                href="#"
                className="text-blue-500 hover:underline"
              >
                Rejoignez Factoconn en tant que fournisseur
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-100 text-center py-4">
        <p className="text-sm text-gray-600">
          La connexion essentielle pour l'industrie
        </p>
        <p className="text-xs text-gray-400 mt-1">
          Conçu avec ❤️ par{" "}
          <a
            href="https://example.com" // Replace with actual LSD link
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-semibold hover:underline"
          >
            LSD
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Login;
