import React from 'react';
import { FiSearch, FiBell } from 'react-icons/fi';
import userImage from '../../images/avatars/avatar.png';

const Topbar: React.FC = () => {
  return (
    <div className="flex justify-between items-center p-4 bg-white shadow-md w-full border-b border-gray-200">
      {/* Barre de Recherche */}
      <div className="relative w-1/3">
        <FiSearch className="absolute left-3 top-3 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Rechercher un produit, fournisseur, commande"
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 shadow-sm text-gray-700"
        />
      </div>

      {/* Icônes et Profil */}
      <div className="flex items-center space-x-6">
        {/* Icône de Notification */}
        <div className="relative cursor-pointer">
          <FiBell size={36} className="text-gray-600 hover:text-blue-500 animate-pulse" />
          <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-semibold px-2 py-0.5 rounded-full border border-white shadow-md">
            3
          </span>
        </div>
        
        {/* Image de Profil */}
        <img
          src={userImage}
          alt="Profil Utilisateur"
          className="w-10 h-10 rounded-full border-2 border-gray-300 cursor-pointer hover:border-blue-400"
        />
      </div>
    </div>
  );
};

export default Topbar;
