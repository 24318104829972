import React from 'react';
import { FiBox, FiUsers, FiTrendingUp, FiPlus } from 'react-icons/fi';

const DashboardOverview: React.FC = () => {
  return (
    <div className="p-8 bg-gradient-to-br from-gray-100 to-gray-200 min-h-screen">
      {/* Dashboard Header */}
      {/* <div className="mb-10 text-center">
        <h1 className="text-5xl font-extrabold text-gray-800 tracking-tight">
          Tableau de bord Factoconn
        </h1>
        <p className="text-lg text-gray-600 mt-2">Vue d'ensemble des statistiques et activités récentes.</p>
      </div> */}

      {/* Key Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-10">
        {[
          { icon: FiBox, title: 'Pièces disponibles', value: 120, color: 'from-gray-300 to-gray-200' },
          { icon: FiUsers, title: 'Fournisseurs actifs', value: 45, color: 'from-gray-300 to-gray-200' },
          { icon: FiTrendingUp, title: 'Commandes récentes', value: 78, color: 'from-gray-300 to-gray-200' },
          { icon: FiUsers, title: 'Demandes urgentes', value: 15, color: 'from-gray-300 to-gray-200' },
        ].map((item, index) => (
          <div
            key={index}
            className={`p-8 rounded-3xl shadow-xl bg-gradient-to-br ${item.color} text-gray-800 flex items-center transform transition hover:scale-105`}
          >
            <item.icon className="text-5xl mr-6" style={{ color: '#105080' }} />
            <div>
              <h2 className="text-5xl font-extrabold" style={{ color: '#105080' }}>
                {item.value}
              </h2>
              <p className="text-lg" style={{ color: '#105080' }}>
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Recent Activities & Chart */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Recent Activities */}
        <div className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-2xl transition">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">
            Activités Récentes
          </h3>
          <ul className="divide-y divide-gray-200">
            {[
              { label: 'Commande #10234', time: 'Il y a 2 heures' },
              { label: 'Nouveau fournisseur ajouté', time: 'Il y a 1 jour' },
              { label: 'Demande de pièce urgente', time: 'Il y a 3 jours' },
            ].map((activity, index) => (
              <li key={index} className="flex justify-between py-5">
                <span className="text-lg">{activity.label}</span>
                <span className="text-sm text-gray-500">{activity.time}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Chart Section */}
        <div className="bg-white rounded-3xl shadow-lg p-8 flex flex-col items-center justify-center hover:shadow-2xl transition">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">
            Statistiques des Ventes
          </h3>
          <div className="h-48 w-full flex items-center justify-center bg-gradient-to-r from-gray-100 to-gray-200 rounded-xl">
            <p className="text-gray-500">Graphique en construction</p>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-800 mb-8 text-center">
          Actions Rapides
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            { label: 'Ajouter une pièce', color: 'bg-blue-500 hover:bg-blue-600' },
            { label: 'Ajouter un fournisseur', color: 'bg-green-500 hover:bg-green-600' },
            { label: 'Créer une commande', color: 'bg-orange-500 hover:bg-orange-600' },
            { label: 'Ajouter une demande urgente', color: 'bg-red-500 hover:bg-red-600' },
          ].map((action, index) => (
            <button
              key={index}
              className={`flex items-center justify-center p-5 rounded-2xl text-white font-semibold shadow-xl ${action.color} transition transform hover:scale-105`}
            >
              <FiPlus className="mr-3" />
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
