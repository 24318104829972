import React, { useState, useEffect } from "react";
import logo from "images/logo/logo.png"; // Mettez à jour avec votre chemin réel du logo
import {
    HomeIcon,
    ClipboardIcon,
    ChartBarIcon,
    UserCircleIcon,
    ShoppingCartIcon,
    CogIcon,
    ArrowLeftIcon,
} from "@heroicons/react/24/outline"; // Pour les icônes, on utilise Heroicons ici.
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar: React.FC = () => {
    // État pour suivre l'élément actif
    const [activeItem, setActiveItem] = useState<string>("dashboard");

    const urlNavigate = useNavigate();
    const location = useLocation(); // To get the current location (URL)

    // Couleur Factoconn (mise à jour avec #105080)
    const factoconnColor = "#fff"; // Couleur bleue pour l'élément actif
    const hoverColor = "#105080"; // Couleur pour le survol des éléments

    // Fonction pour gérer le clic et définir l'élément actif
    const handleClick = (item: string) => {
        setActiveItem(item);
        urlNavigate(`/${item}`);
    };

    // Update the active item when the URL changes
    useEffect(() => {
        const currentPath = location.pathname.split("/")[1]; // Extract the path after "/"
        setActiveItem(currentPath || "dashboard"); // Default to "dashboard" if path is empty
    }, [location]);

    return (
        <div className="flex flex-col h-screen bg-white shadow-md w-64 overflow-y-hidden">
            {/* Section Logo */}
            <div className="flex items-center justify-center py-6 border-b">
                <img src={logo} alt="Logo" className="h-20" />
            </div>

            {/* Éléments de navigation */}
            <nav className="flex-1 px-4 py-4">
                <ul className="space-y-6">
                    {/* Dashboard */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "dashboard"
                                ? `text-[#fff] bg-[#105080] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("dashboard")}
                    >
                        <HomeIcon className="w-5 h-5" />
                        <span>Tableau de bord</span>
                    </li>

                    {/* Inventory */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "inventory"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("inventory")}
                    >
                        <ClipboardIcon className="w-5 h-5" />
                        <span>Inventaire</span>
                    </li>

                    {/* Reports */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "reports"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("reports")}
                    >
                        <ChartBarIcon className="w-5 h-5" />
                        <span>Rapports</span>
                    </li>

                    {/* Suppliers */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "suppliers"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("suppliers")}
                    >
                        <UserCircleIcon className="w-5 h-5" />
                        <span>Fournisseurs</span>
                    </li>

                    {/* Orders */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "orders"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("orders")}
                    >
                        <ShoppingCartIcon className="w-5 h-5" />
                        <span>Commandes</span>
                    </li>

                    {/* Manage Store */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "manageStore"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("store-management")}
                    >
                        <ClipboardIcon className="w-5 h-5" />
                        <span>Gérer le magasin</span>
                    </li>
                </ul>
            </nav>

            {/* Section pied de page */}
            <div className="border-t px-4 py-4">
                <ul className="space-y-4">
                    {/* Settings */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "settings"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-[${hoverColor}] hover:bg-[${hoverColor}] hover:text-white"
                        }`}
                        onClick={() => handleClick("settings")}
                    >
                        <CogIcon className="w-5 h-5" />
                        <span>Paramètres</span>
                    </li>

                    {/* Log Out */}
                    <li
                        className={`flex items-center space-x-3 cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                            activeItem === "logout"
                                ? `text-[${factoconnColor}] bg-[${hoverColor}] font-semibold shadow-md`
                                : "text-gray-600 hover:text-red-600 hover:bg-red-100 hover:text-white"
                        }`}
                        onClick={() => handleClick("logout")}
                    >
                        <ArrowLeftIcon className="w-5 h-5" />
                        <span>Se déconnecter</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
