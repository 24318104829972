import React from 'react';
import { Line, Pie } from 'react-chartjs-2'; // Correct import for both charts
//@ts-ignore
import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, PointElement, LineElement } from 'chart.js'; // Import necessary components
import { FaDownload } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

// Register components with chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
  PointElement, // Register PointElement for points in the Line chart
  LineElement
);

// Your component...
const Reports: React.FC = () => {
  // Example data for Pie chart
  const pieData = {
    labels: ['In Stock', 'Out of Stock', 'Pending'],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ['#4CAF50', '#FF6347', '#FFEB3B'],
      },
    ],
  };

  // Example data for Line chart
  const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Orders',
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        borderColor: '#2196F3',
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 min-h-screen">
        <Topbar />

        <div className="p-6 bg-gray-50 min-h-screen">
          {/* Report Filters */}
          <div className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Filtrer les rapports</h2>
            <div className="flex space-x-4">
              <select className="p-3 border rounded-lg text-sm">
                <option value="last-week">La semaine dernière</option>
                <option value="last-month">Le mois dernier</option>
                <option value="custom">Plage personnalisée</option>
              </select>
              <button className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 text-sm">
                Appliquer le filtre
              </button>
            </div>
          </div>

          {/* Key Metrics Summary */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {[
              { title: 'Total des commandes', value: 150, color: 'from-blue-500 to-blue-400' },
              { title: 'Pièces en stock', value: 45, color: 'from-green-500 to-green-400' },
              { title: 'Fournisseurs actifs', value: 32, color: 'from-orange-500 to-orange-400' },
            ].map((metric, index) => (
              <div
                key={index}
                className={`p-4 rounded-xl shadow-lg bg-gradient-to-br ${metric.color} text-white`}
              >
                <h3 className="text-2xl font-bold">{metric.value}</h3>
                <p className="text-sm">{metric.title}</p>
              </div>
            ))}
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Orders Trend */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Tendance des commandes</h3>
              <Line data={lineData} />
            </div>

            {/* Inventory Status */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">État de l'inventaire</h3>
              <Pie data={pieData} />
            </div>
          </div>

          {/* Report Actions */}
          <div className="mt-8 text-center">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Actions Rapides</h3>
            <div className="flex justify-center space-x-4">
              <button className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 text-sm">
                Télécharger le rapport <FaDownload className="inline ml-2" />
              </button>
              <button className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 text-sm">
                Voir les rapports passés
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
