import React, { useState } from 'react';
import { FaPlus, FaSearch, FaEdit, FaTrashAlt } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const StoreManagement: React.FC = () => {
    const [products, setProducts] = useState<any[]>([
        { id: 1, name: 'Pièce A', category: 'Mécanique', quantity: 50, price: 5000, supplier: 'Fournisseur A', status: 'En stock' },
        { id: 2, name: 'Pièce B', category: 'Électronique', quantity: 10, price: 8000, supplier: 'Fournisseur B', status: 'En rupture de stock' },
        { id: 3, name: 'Pièce C', category: 'Mécanique', quantity: 30, price: 6000, supplier: 'Fournisseur C', status: 'En stock' },
    ]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.category.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 min-h-screen">
                <Topbar />

                <div className="p-8 min-h-screen bg-gray-100">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-6">Gestion du Magasin</h2>

                    {/* Barre de recherche */}
                    <div className="flex justify-between items-center mb-6">
                        <div className="relative w-1/3">
                            <input
                                type="text"
                                placeholder="Rechercher un produit"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FaSearch className="absolute right-4 top-3 text-gray-600" />
                        </div>
                        <button
                            onClick={() => setShowAddModal(true)}
                            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center"
                        >
                            <FaPlus className="mr-2" /> Ajouter un produit
                        </button>
                    </div>

                    {/* Liste des produits */}
                    <div className="bg-white p-8 rounded-xl shadow-lg">
                        <table className="table-auto w-full border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border text-left">Nom du Produit</th>
                                    <th className="px-4 py-2 border text-left">Catégorie</th>
                                    <th className="px-4 py-2 border text-left">Quantité</th>
                                    <th className="px-4 py-2 border text-left">Prix</th>
                                    <th className="px-4 py-2 border text-left">Fournisseur</th>
                                    <th className="px-4 py-2 border text-left">Statut</th>
                                    <th className="px-4 py-2 border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map((product) => (
                                    <tr key={product.id}>
                                        <td className="px-4 py-2 border">{product.name}</td>
                                        <td className="px-4 py-2 border">{product.category}</td>
                                        <td className="px-4 py-2 border">{product.quantity}</td>
                                        <td className="px-4 py-2 border">{product.price} FCFA</td>
                                        <td className="px-4 py-2 border">{product.supplier}</td>
                                        <td className="px-4 py-2 border">
                                            <span
                                                className={`px-2 py-1 rounded-full ${product.status === 'En stock'
                                                        ? 'bg-green-100 text-green-600'
                                                        : 'bg-red-100 text-red-600'
                                                    }`}
                                            >
                                                {product.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2 border">
                                            <button className="text-blue-500 mr-4">
                                                <FaEdit />
                                            </button>
                                            <button className="text-red-500">
                                                <FaTrashAlt />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Modal pour Ajouter un produit */}
                    {showAddModal && (
                        <div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
                            onClick={() => setShowAddModal(false)}
                        >
                            <div className="bg-white p-6 rounded-xl w-96" onClick={(e) => e.stopPropagation()}>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Ajouter un nouveau produit</h3>
                                {/* Formulaire d'ajout de produit */}
                                <form>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Nom du produit</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Catégorie</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Quantité</label>
                                        <input
                                            type="number"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Prix</label>
                                        <input
                                            type="number"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Fournisseur</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Statut</label>
                                        <select className="w-full px-4 py-2 border rounded-lg mt-2">
                                            <option value="En stock">En stock</option>
                                            <option value="En rupture de stock">En rupture de stock</option>
                                        </select>
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <button
                                            type="button"
                                            className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg"
                                            onClick={() => setShowAddModal(false)}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            type="submit"
                                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StoreManagement;
