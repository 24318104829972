import React, { useState } from 'react';
import { FiSearch, FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Inventory: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [inventoryItems, setInventoryItems] = useState([
        { id: 1, name: 'Moteur électrique', partNumber: 'A12345', quantity: 50, price: 120000, supplier: 'Fournisseur X', status: 'In Stock' },
        { id: 2, name: 'Pneu industriel', partNumber: 'B67890', quantity: 0, price: 80000, supplier: 'Fournisseur Y', status: 'Out of Stock' },
        { id: 3, name: 'Vanne hydraulique', partNumber: 'C23456', quantity: 30, price: 150000, supplier: 'Fournisseur Z', status: 'Low Stock' },
    ]);

    const filteredItems = inventoryItems.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.partNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 min-h-screen">
                <Topbar />

                <div className="p-6 bg-gradient-to-br from-gray-100 to-gray-200 min-h-screen">
                    {/* Header */}
                    <div className="flex justify-between items-center mb-8">
                        <h1 className="text-3xl font-extrabold text-gray-800">Gestion de l'Inventaire</h1>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                placeholder="Rechercher une pièce..."
                                className="p-2 rounded-lg shadow-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button className="flex items-center bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-xl shadow-md transition">
                                <FiPlus className="mr-2" />
                                Ajouter une pièce
                            </button>
                        </div>
                    </div>

                    {/* Inventory Table */}
                    <div className="overflow-x-auto bg-white rounded-2xl shadow-lg p-6">
                        <table className="w-full table-auto">
                            <thead>
                                <tr className="text-left bg-gray-100">
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Nom de la pièce</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Référence</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Quantité</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Prix</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Fournisseur</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Statut</th>
                                    <th className="py-3 px-4 text-sm font-semibold text-gray-700">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredItems.map(item => (
                                    <tr key={item.id} className="hover:bg-gray-50">
                                        <td className="py-4 px-4 text-sm text-gray-800">{item.name}</td>
                                        <td className="py-4 px-4 text-sm text-gray-600">{item.partNumber}</td>
                                        <td className="py-4 px-4 text-sm text-gray-800">{item.quantity}</td>
                                        <td className="py-4 px-4 text-sm text-gray-800">{item.price} F</td>
                                        <td className="py-4 px-4 text-sm text-gray-600">{item.supplier}</td>
                                        <td className={`py-4 px-4 text-sm font-semibold ${item.status === 'In Stock' ? 'text-green-500' : item.status === 'Out of Stock' ? 'text-red-500' : 'text-orange-500'}`}>
                                            {item.status}
                                        </td>
                                        <td className="py-4 px-4 flex space-x-2">
                                            <button className="text-blue-500 hover:text-blue-600">
                                                <FiEdit size={18} />
                                            </button>
                                            <button className="text-red-500 hover:text-red-600">
                                                <FiTrash size={18} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Footer Statistics */}
                    <div className="mt-8 text-center">
                        <p className="text-gray-500">Total des pièces en stock: {filteredItems.length}</p>
                        <p className="text-gray-500">Pièces hors stock: {filteredItems.filter(item => item.status === 'Out of Stock').length}</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Inventory;
