import React from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { Route, Routes } from 'react-router-dom'
import DashboardOverview from './DashboardOverview'
import Inventory from './Inventory'
import Reports from './Reports'
import Suppliers from './Suppliers'
import Orders from './Orders'
import StoreManagement from './StoreManagement'

const Dashboard = () => {
  return (
    <div className="flex">
    <Sidebar />
    <div className="flex-1 min-h-screen">
      <Topbar />
      <main className="p-6">
        <Routes>
          <Route path="/" element={<DashboardOverview />} />
          <Route path="/inventory" element={<Inventory/>} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/store-management" element={<StoreManagement />} />
        </Routes>
      </main>
    </div>
  </div>
  )
}

export default Dashboard;