import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FaPlus, FaEdit, FaTrash, FaDownload } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

// Exemple de données de graphique
const performanceData = {
    labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai'],
    datasets: [
        {
            label: 'Livraisons à l\'heure',
            data: [75, 80, 85, 90, 95],
            fill: false,
            borderColor: '#4CAF50',
            tension: 0.1,
        },
        {
            label: 'Disponibilité des stocks',
            data: [65, 70, 75, 80, 85],
            fill: false,
            borderColor: '#FF6347',
            tension: 0.1,
        },
    ],
};

const Suppliers: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [supplierData, setSupplierData] = useState<any>({
        name: '',
        contact: '',
        status: 'Actif',
        type: 'Fabricant',
    });

    // Liste des fournisseurs fictifs pour l'affichage
    const suppliers: any = [
        { id: 1, name: 'Fournisseur A', contact: 'john@example.com', status: 'Actif' },
        { id: 2, name: 'Fournisseur B', contact: 'jane@example.com', status: 'Inactif' },
    ];

    // Gestion de l'ajout/édition d'un fournisseur
    const handleAddSupplier = () => {
        // Logique pour ajouter ou éditer le fournisseur
        setShowModal(false);
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 min-h-screen">
                <Topbar />

                <div className="bg-gray-50 p-8 min-h-screen overflow-y-auto">
                    {/* Tableau de bord des fournisseurs */}
                    <div className="mb-8">
                        {/* <h2 className="text-3xl font-semibold text-gray-800 mb-6">Tableau de bord des fournisseurs</h2> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <h3 className="text-xl font-bold text-gray-800">Fournisseurs Totaux</h3>
                                <p className="text-3xl text-blue-600">{suppliers.length}</p>
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <h3 className="text-xl font-bold text-gray-800">Fournisseurs Actifs</h3>
                                <p className="text-3xl text-green-500">
                                    {suppliers.filter((s: any) => s.status === 'Actif').length}
                                </p>
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <h3 className="text-xl font-bold text-gray-800">Fournisseurs Inactifs</h3>
                                <p className="text-3xl text-red-500">
                                    {suppliers.filter((s: any) => s.status === 'Inactif').length}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Liste des fournisseurs */}
                    <div className="bg-white p-8 rounded-xl shadow-lg mb-10">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-2xl font-semibold text-gray-800">Liste des fournisseurs</h3>
                            <button
                                onClick={() => setShowModal(true)}
                                className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center"
                            >
                                <FaPlus className="mr-2" /> Ajouter un fournisseur
                            </button>
                        </div>

                        <table className="table-auto w-full border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border">Nom</th>
                                    <th className="px-4 py-2 border">Contact</th>
                                    <th className="px-4 py-2 border">Statut</th>
                                    <th className="px-4 py-2 border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {suppliers.map((supplier: any) => (
                                    <tr key={supplier.id}>
                                        <td className="px-4 py-2 border">{supplier.name}</td>
                                        <td className="px-4 py-2 border">{supplier.contact}</td>
                                        <td className="px-4 py-2 border">
                                            <span
                                                className={`px-2 py-1 rounded-full ${supplier.status === 'Actif' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                                                    }`}
                                            >
                                                {supplier.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2 border">
                                            <button className="text-blue-500 mr-4">
                                                <FaEdit />
                                            </button>
                                            <button className="text-red-500">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Performance des fournisseurs */}
                    <div className="bg-white p-8 rounded-xl shadow-lg mb-10">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-6">Performance des fournisseurs</h3>
                        <Line data={performanceData} options={{ responsive: true }} />
                    </div>

                    {/* Modal pour Ajouter/Éditer un fournisseur */}
                    {showModal && (
                        <div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
                            onClick={() => setShowModal(false)}
                        >
                            <div
                                className="bg-white p-6 rounded-xl w-96"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {supplierData.id ? 'Éditer le fournisseur' : 'Ajouter un fournisseur'}
                                </h3>

                                <form onSubmit={handleAddSupplier}>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Nom</label>
                                        <input
                                            type="text"
                                            value={supplierData.name}
                                            onChange={(e) =>
                                                setSupplierData({ ...supplierData, name: e.target.value })
                                            }
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Contact</label>
                                        <input
                                            type="text"
                                            value={supplierData.contact}
                                            onChange={(e) =>
                                                setSupplierData({ ...supplierData, contact: e.target.value })
                                            }
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Statut</label>
                                        <select
                                            value={supplierData.status}
                                            onChange={(e) =>
                                                setSupplierData({ ...supplierData, status: e.target.value })
                                            }
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        >
                                            <option value="Actif">Actif</option>
                                            <option value="Inactif">Inactif</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Type de fournisseur</label>
                                        <select
                                            value={supplierData.type}
                                            onChange={(e) =>
                                                setSupplierData({ ...supplierData, type: e.target.value })
                                            }
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        >
                                            <option value="Fabricant">Fabricant</option>
                                            <option value="Distributeur">Distributeur</option>
                                        </select>
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <button
                                            type="button"
                                            className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            type="submit"
                                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                        >
                                            Sauvegarder
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Suppliers;
