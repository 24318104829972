import React, { useState } from 'react';
import { FaPlus, FaSearch, FaDownload, FaCheck, FaTimes } from 'react-icons/fa';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Orders: React.FC = () => {
    const [orders, setOrders] = useState<any[]>([
        { id: 1, orderId: 'ORD001', supplier: 'Fournisseur A', status: 'En attente', date: '2024-12-21' },
        { id: 2, orderId: 'ORD002', supplier: 'Fournisseur B', status: 'Approuvé', date: '2024-12-18' },
        { id: 3, orderId: 'ORD003', supplier: 'Fournisseur C', status: 'Rejeté', date: '2024-12-15' },
    ]);

    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Recherche des commandes
    const filteredOrders = orders.filter(order =>
        order.orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.supplier.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 min-h-screen">
                <Topbar />

                <div className="p-8 min-h-screen bg-gray-100">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-6">Commandes Factoconn</h2>

                    {/* Barre de recherche */}
                    <div className="flex justify-between items-center mb-6">
                        <div className="relative w-1/3">
                            <input
                                type="text"
                                placeholder="Rechercher Commandes"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FaSearch className="absolute right-4 top-3 text-gray-600" />
                        </div>
                        <button
                            onClick={() => setShowModal(true)}
                            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center"
                        >
                            <FaPlus className="mr-2" /> Ajouter une commande
                        </button>
                    </div>

                    {/* Liste des commandes */}
                    <div className="bg-white p-8 rounded-xl shadow-lg">
                        <table className="table-auto w-full border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border text-left">ID Commande</th>
                                    <th className="px-4 py-2 border text-left">Fournisseur</th>
                                    <th className="px-4 py-2 border text-left">Statut</th>
                                    <th className="px-4 py-2 border text-left">Date</th>
                                    <th className="px-4 py-2 border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map((order) => (
                                    <tr key={order.id}>
                                        <td className="px-4 py-2 border">{order.orderId}</td>
                                        <td className="px-4 py-2 border">{order.supplier}</td>
                                        <td className="px-4 py-2 border">
                                            <span
                                                className={`px-2 py-1 rounded-full ${order.status === 'En attente'
                                                        ? 'bg-yellow-100 text-yellow-600'
                                                        : order.status === 'Approuvé'
                                                            ? 'bg-green-100 text-green-600'
                                                            : 'bg-red-100 text-red-600'
                                                    }`}
                                            >
                                                {order.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2 border">{order.date}</td>
                                        <td className="px-4 py-2 border">
                                            <button className="text-blue-500 mr-4">
                                                <FaCheck />
                                            </button>
                                            <button className="text-red-500 mr-4">
                                                <FaTimes />
                                            </button>
                                            <button className="text-gray-500">
                                                <FaDownload />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Modal pour Ajouter une commande */}
                    {showModal && (
                        <div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
                            onClick={() => setShowModal(false)}
                        >
                            <div className="bg-white p-6 rounded-xl w-96" onClick={(e) => e.stopPropagation()}>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Ajouter une nouvelle commande</h3>
                                {/* Formulaire d'ajout de commande */}
                                <form>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">ID Commande</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Fournisseur</label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Statut</label>
                                        <select className="w-full px-4 py-2 border rounded-lg mt-2">
                                            <option value="En attente">En attente</option>
                                            <option value="Approuvé">Approuvé</option>
                                            <option value="Rejeté">Rejeté</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold text-gray-600">Date de la commande</label>
                                        <input
                                            type="date"
                                            className="w-full px-4 py-2 border rounded-lg mt-2"
                                        />
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <button
                                            type="button"
                                            className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            type="submit"
                                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Orders;
